import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';

const endpoint: string = environment.apiUrl;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private isLoggedIn = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {}

  // Toogle Loggedin
  toggleLogin(state: boolean): void {
    this.isLoggedIn.next(state);
  }

  // Status
  status() {
    const localData: any = localStorage.getItem('user');
    if (!localData) {
      this.isLoggedIn.next(false);
    } else {
      const userObj = JSON.parse(localData);
      const token_expires_at = new Date(userObj.token_expires_at);
      const current_date = new Date();
      if (token_expires_at > current_date) {
        this.isLoggedIn.next(true);
      } else {
        this.isLoggedIn.next(false);
         console.log('Token Expires!!');
      }
    }
    return this.isLoggedIn.asObservable();
  }

  // Login
  login(email: string, password: string) {
    return this.http.post(endpoint + '/api/login', {
      email: email,
      password: password,
    });
  }

  // User Info
  user() {
    const user: any = localStorage.getItem('user');
    const userObj = JSON.parse(user);

    const token = userObj.token;
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http.get('/api/user', {
      headers: headers,
    });
  }

  isAuthenticate (): boolean {
    return !!localStorage.getItem('token')
  }

  getToken (): string {
    return localStorage.getItem('token') ?? ''
  }

  setToken (token: string): void {
    localStorage.setItem('token', token) 
  }

  // Logout
  logout(allDevice: boolean){
    return this.http.post(endpoint + '/api/logout', {allDevice:allDevice}, httpOptions);
  }

  // Register
  register(param : any){
    return this.http.post(endpoint + '/api/register', param);
  }

  // Forgot Pass
  forgot(email:string){
    return this.http.post(endpoint + '/api/forgot', {email:email});
  }

  // Reset Pass
  reset(token:string, password:string,password_confirmation:string){

    const data={
      token:token,
      password:password,
      password_confirmation:password_confirmation
    }
    return this.http.post(endpoint + '/api/reset', data);
  }
}
